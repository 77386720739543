import React from "react";
import styled from "styled-components";

const DefaultStyles = styled.div`
  .page-title {
    padding: 4rem 3rem 0;

    h1 {
      font-size: 2.4rem;
      line-height: 3rem;
      border-bottom: 3px solid var(--text);
      padding-bottom: 2.5rem;
    }
  }

  .page-content {
    padding: 2rem 3rem 1rem;

    .content-container {
      color: var(--p-grey);
      margin-bottom: 4rem;

      p,
      ul,
      ol,
      img,
      blockquote {
        font-size: 1.4rem;
        line-height: 2.1rem;
        margin-bottom: 3rem;
        margin-top: 0;
      }

      figure {
        margin: 0;
        padding: 0;
      }

      blockquote {
        font-style: italic;
        font-weight: 700;
        margin-left: 0;
        margin-right: 0;
        position: relative;
        padding-left: 9rem;

        p {
          margin-bottom: 1rem;
        }

        cite {
          font-weight: 400;
        }

        &:before {
          content: "“";
          display: block;
          z-index: 3;
          position: absolute;
          top: 63px;
          left: -24px;
          font-size: 180px;
          font-weight: 700;
          color: var(--grey);
          margin: 0;
          padding: 0;
        }
      }
    }
  }

  @media screen and (min-width: 981px) {
    .container {
      max-width: 1220px;
    }

    .page-title {
      padding: 8rem 0 0;

      h1 {
        font-size: 6rem;
        line-height: 6rem;
        margin-bottom: 7.5rem;
      }
    }

    .page-content {
      padding: 1rem 3rem 2rem;

      .content-container {
        p,
        ul,
        ol,
        img,
        blockquote {
          font-size: 1.6rem;
          margin-bottom: 4rem;
        }
      }
    }
  }
`;

const Default = ({ data }) => (
  <DefaultStyles>
    <div className="page-title">
      <div className="container">
        <h1>{data.title}</h1>
      </div>
    </div>
    <div className="page-content content">
      <div className="container">
        <div className="row">
          <div className="content-container" dangerouslySetInnerHTML={{ __html: data.content }} />
        </div>
      </div>
    </div>
  </DefaultStyles>
);

export default Default;
