import styled from "styled-components";

const ProcessStyles = styled.div`
  .page-title {
    padding: 3rem;

    h1 {
      font-size: 2.4rem;
      line-height: 3rem;
      border-bottom: 3px solid var(--text);
      padding-bottom: 1.5rem;
      margin-bottom: 3rem;
      text-transform: lowercase;
    }

    p {
      font-size: 1.4rem;
      line-height: 1.6rem;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .page-content {
    .col:first-child {
      text-align: center;
    }

    h2 {
      margin-bottom: 3rem;
      font-size: 2.4rem;
      line-height: 3.2rem;
    }

    p {
      line-height: 2.1rem;
      margin: 0 0 3rem;

      &:last-child {
        margin-bottom: 0;
      }
    }

    ul {
      margin: 0 0 3rem;
      padding: 0;
      list-style: none;
      font-size: 1.6rem;
      line-height: 1.9rem;

      li {
        position: relative;
        margin-bottom: 1rem;
        padding-left: 2.5rem;
        font-weight: 700;

        &:before {
          content: "+";
          display: block;
          position: absolute;
          top: 1px;
          left: 0;
          color: var(--p-grey);
          line-height: 1;
        }
      }
    }

    .full-width {
      .content {
        padding-top: 0;
      }

      .col {
        text-align: left;
      }
    }

    .gatsby-image-wrapper {
      padding-bottom: 100%;
    }
  }

  .process-list {
    color: var(--white);
    background-size: cover;
    padding: 3rem;
    margin-bottom: 2rem;
    position: relative;
    z-index: 1;

    &:before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.3);
      z-index: -1;
    }

    .img-wrap {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: -1;

      .gatsby-image-wrapper {
        width: 100%;
        height: 100%;
      }
    }

    h2 {
      margin-bottom: 3rem;
    }

    ul {
      padding: 0;
      margin: 0;
      list-style: none;

      li {
        padding-left: 5rem;
        margin-bottom: 2rem;
        position: relative;

        .gg-arrow-long-right {
          box-sizing: border-box;
          position: absolute;
          display: block;
          border-top: 2px solid transparent;
          border-bottom: 2px solid transparent;
          box-shadow: inset 0 0 0 2px;
          width: 30px;
          height: 6px;
          left: 0;
          top: 9px;
        }

        .gg-arrow-long-right::after {
          content: "";
          display: block;
          box-sizing: border-box;
          position: absolute;
          width: 12px;
          height: 12px;
          border-top: 2px solid;
          border-right: 2px solid;
          transform: rotate(45deg);
          right: -1px;
          top: -5px;
        }

        &:last-child {
          margin-bottom: 0;
        }

        h3 {
          display: inline;
          font-size: 2rem;
          line-height: 2.4rem;
        }

        p {
          display: inline;
          margin: 0;
          font-size: 1.6rem;
          line-height: 1.9rem;
        }
      }
    }
  }

  .process-slider {
    padding-bottom: 2rem;

    .swiper-slide {
      position: relative;
      background-size: cover;
      width: 75% !important;
      overflow: hidden;

      &:first-child {
        margin-left: 1rem !important;
      }

      &:after {
        content: "";
        display: block;
      }

      .gatsby-image-wrapper {
        padding-bottom: 150%;
      }

      a {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: calc(100% - 4rem);
        height: calc(100% - 4rem);
        padding: 2rem;
        color: var(--white);
        /*font-family: "Roboto Condensed", sans-serif;*/
        line-height: 1.7rem;
        font-weight: 700;
        text-decoration: none;
        z-index: 2;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;

        &:after {
          content: "";
          position: absolute;
          opacity: 1;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, 0.5);
          transition: opacity 0.3s ease;
          z-index: -1;
        }

        h3 {
          font-size: 1.6rem;
          line-height: 1.9rem;
          margin-bottom: 0;
        }

        p {
          display: none;
          font-weight: 300;
          margin-top: 0;

          &:last-child {
            margin-bottom: 0;
          }
        }

        div {
          display: none;
          max-height: 64px;
          overflow: hidden;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
        }
      }
    }
  }

  .book {
    background-size: cover;
    padding: 8rem 3rem;
    color: var(--white);
    position: relative;

    /*&:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      width: 100%;
      height: 100%;
      background: var(--text);
      background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(41, 41, 41, 1) 100%);
    }*/

    .img-wrap {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: -1;

      .gatsby-image-wrapper {
        width: 100%;
        height: 100%;
      }
    }

    h2 {
      font-size: 2.4rem;
      line-height: 3.2rem;
    }

    p {
      line-height: 2rem;
      margin-bottom: 2rem;
    }

    .button {
      margin-bottom: 0;
    }

    .book-inner {
      position: relative;
      z-index: 2;
    }
  }

  @media screen and (min-width: 981px) {
    .page-title {
      padding: 8rem 0 9rem;

      .container {
        max-width: 1220px;
      }

      h1 {
        font-size: 6rem;
        line-height: 6rem;
        margin-bottom: 3.5rem;
        padding-bottom: 2.5rem;
      }

      p {
        font-size: 1.6rem;
        line-height: 2.1rem;
      }
    }

    .page-content {
      padding-bottom: 6.5rem;

      .container {
        max-width: 1220px;

        .row {
          align-items: center;

          .col {
            width: 50%;

            .content {
              padding: 0;

              h2 {
                font-size: 3rem;
              }

              ul {
                columns: 3;
                column-gap: 3vw;
              }
            }

            &:first-child {
              width: calc(50% - 6rem);
              margin-right: 6rem;
            }
          }
        }

        .full-width {
          padding-top: 9rem;

          .col {
            flex-basis: 100%;
            text-align: left;
          }
        }
      }
    }

    .process-slider {
      padding-bottom: 2.5rem;

      .swiper-container {
        position: relative;
        padding-top: 2.5rem;
      }

      .swiper-slide {
        width: 30% !important;
        transition: transform 0.3s ease;

        &:first-child {
          margin-left: 2.5rem !important;
        }

        &:hover {
          transform: translateY(-2.5rem);
        }

        a {
          font-size: 1.6rem;
          padding: 6rem;
          width: calc(100% - 12rem);
          height: calc(100% - 12rem);

          h3 {
            font-size: 3rem;
            line-height: 3.6rem;
            margin-bottom: 1.5rem;
            width: 50%;
          }

          p {
            display: block;
            font-weight: 400;
            font-size: 1.6rem;
            line-height: 2.1rem;
            width: 50%;
          }

          div {
            display: -webkit-box;
          }
        }
      }

      .swiper-button-disabled {
        opacity: 0.5 !important;
      }

      .swiper-button-prev {
        color: var(--white);
        z-index: 3;
        top: calc(50% - 15px);
        left: 5rem;
        cursor: pointer;
        opacity: 0.8;
        box-sizing: border-box;
        position: absolute;
        display: block;
        border-top: 2px solid transparent;
        border-bottom: 2px solid transparent;
        box-shadow: inset 0 0 0 2px;
        width: 30px;
        height: 6px;
      }

      .swiper-button-prev::after {
        content: "";
        display: block;
        box-sizing: border-box;
        position: absolute;
        width: 12px;
        height: 12px;
        border-bottom: 2px solid;
        border-left: 2px solid;
        transform: rotate(45deg);
        left: -1px;
        top: -5px;
      }

      .swiper-button-next {
        box-sizing: border-box;
        z-index: 3;
        color: var(--white);
        top: calc(50% - 15px);
        right: 2.5rem;
        cursor: pointer;
        opacity: 0.8;
        position: absolute;
        display: block;
        border-top: 2px solid transparent;
        border-bottom: 2px solid transparent;
        box-shadow: inset 0 0 0 2px;
        width: 30px;
        height: 6px;
      }

      .swiper-button-next::after {
        content: "";
        display: block;
        box-sizing: border-box;
        position: absolute;
        width: 12px;
        height: 12px;
        border-top: 2px solid;
        border-right: 2px solid;
        transform: rotate(45deg);
        right: -1px;
        top: -5px;
      }

      .swiper-button-prev,
      .swiper-button-next {
        transition: opacity 0.3s ease;

        &:hover {
          opacity: 1;
        }
      }
    }

    .process-list {
      padding: 6rem 3rem;

      h2 {
        text-align: center;
        font-size: 3rem;
        line-height: 4.5rem;
        margin-bottom: 6rem;
      }

      ul {
        column-count: 3;
        column-gap: 3vw;

        li {
          break-inside: avoid-column;

          h3 {
            display: block;
            margin-bottom: 1rem;
            margin-top: 0;
          }
        }
      }
    }

    .book {
      padding: 12rem 3rem;

      h2 {
        font-size: 6rem;
        line-height: 6rem;
      }

      .row {
        .col {
          width: 50%;
          margin-left: 50%;
        }
      }
    }
  }
`;

export default ProcessStyles;
