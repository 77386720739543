import React from "react";
import { graphql } from "gatsby";
import Seo from "gatsby-plugin-wpgraphql-seo";
import Layout from "../components/layout";
import Home from "../components/home";
import Creative from "../components/creative";
import Production from "../components/production";
import Distribution from "../components/distribution";
import Work from "../components/work";
import VideoPage from "../components/video";
import About from "../components/about";
import DefaultPage from "../components/default";

const PageWrap = ({ data }) => {
  const slug = data.wpgraphql.page.slug;
  const parent = data.wpgraphql.page.parent;
  const components = {
    home: Home,
    creative: Creative,
    production: Production,
    distribution: Distribution,
    work: Work,
    about: About,
  };

  return (
    <Layout page={slug}>
      <Seo post={data.wpgraphql.page} />

      {slug && slug in components ? (
        React.createElement(
          components[slug],
          { data: data.wpgraphql.page, works: data.wpgraphql.works },
          null
        )
      ) : parent && parent.node.slug === "work" ? (
        <VideoPage data={data.wpgraphql.page} />
      ) : (
        <DefaultPage data={data.wpgraphql.page} />
      )}
    </Layout>
  );
};

export default PageWrap;

export const query = graphql`
  query($databaseId: ID!) {
    wpgraphql {
      page(id: $databaseId, idType: DATABASE_ID) {
        title
        content(format: RENDERED)
        slug
        parent {
          node {
            slug
          }
        }
        seo {
          title
          metaDesc
          focuskw
          metaKeywords
          metaRobotsNoindex
          metaRobotsNofollow
          opengraphTitle
          opengraphDescription
          opengraphImage {
            altText
            sourceUrl
            srcSet
          }
          twitterTitle
          twitterDescription
          twitterImage {
            altText
            sourceUrl
            srcSet
          }
          canonical
          cornerstone
          schema {
            articleType
            pageType
            raw
          }
        }
        acfHome {
          bottomBanner {
            content
            title
            button {
              title
              url
            }
            image {
              imageFile {
                childImageSharp {
                  fluid {
                    base64
                    tracedSVG
                    srcWebp
                    srcSetWebp
                    originalImg
                    originalName
                  }
                }
              }
              sourceUrl
            }
          }
          mainContent {
            content
            subTitle
            title
          }
          process {
            content
            title
            button {
              title
              url
            }
          }
          testimonials {
            author
            content
            title
          }
          topBanner {
            content
            subTitle
            title
            button {
              title
              url
            }
            image {
              imageFile {
                childImageSharp {
                  fluid {
                    base64
                    tracedSVG
                    srcWebp
                    srcSetWebp
                    originalImg
                    originalName
                  }
                }
              }
              sourceUrl
            }
          }
        }
        acfCreative {
          banner {
            button {
              title
              url
            }
            title
            image {
              mediaItemUrl
              imageFile {
                childImageSharp {
                  fluid {
                    base64
                    tracedSVG
                    srcWebp
                    srcSetWebp
                    originalImg
                    originalName
                  }
                }
              }
            }
          }
          content {
            content
            image {
              imageFile {
                childImageSharp {
                  fluid {
                    base64
                    tracedSVG
                    srcWebp
                    srcSetWebp
                    originalImg
                    originalName
                  }
                }
              }
              mediaItemUrl
              altText
            }
          }
        }
        acfProduction {
          productionBanner {
            button {
              title
              url
            }
            image {
              altText
              mediaItemUrl
              imageFile {
                childImageSharp {
                  fluid {
                    base64
                    tracedSVG
                    srcWebp
                    srcSetWebp
                    originalImg
                    originalName
                  }
                }
              }
            }
            title
          }
          productionContent {
            contentBottom
            contentRight
            image {
              altText
              imageFile {
                childImageSharp {
                  fluid {
                    base64
                    tracedSVG
                    srcWebp
                    srcSetWebp
                    originalImg
                    originalName
                  }
                }
              }
              mediaItemUrl
            }
          }
          types {
            title
            content {
              content
              title
            }
            image {
              altText
              mediaItemUrl
              imageFile {
                childImageSharp {
                  fluid {
                    base64
                    tracedSVG
                    srcWebp
                    srcSetWebp
                    originalImg
                    originalName
                  }
                }
              }
            }
          }
        }
        acfDistribution {
          distributionBanner {
            button {
              title
              url
            }
            image {
              altText
              imageFile {
                childImageSharp {
                  fluid {
                    base64
                    tracedSVG
                    srcWebp
                    srcSetWebp
                    originalImg
                    originalName
                  }
                }
              }
              mediaItemUrl
            }
            title
          }
          distributionContent {
            content
            image {
              altText
              imageFile {
                childImageSharp {
                  fluid {
                    base64
                    tracedSVG
                    srcWebp
                    srcSetWebp
                    originalImg
                    originalName
                  }
                }
              }
              mediaItemUrl
            }
          }
          distributionTypes {
            content {
              content
              title
            }
            image {
              altText
              imageFile {
                childImageSharp {
                  fluid {
                    base64
                    tracedSVG
                    srcWebp
                    srcSetWebp
                    originalImg
                    originalName
                  }
                }
              }
              mediaItemUrl
            }
            title
          }
        }
        acfAbout {
          aboutBottomBanner {
            button {
              title
              url
            }
            content
            image {
              altText
              imageFile {
                childImageSharp {
                  fluid {
                    base64
                    tracedSVG
                    srcWebp
                    srcSetWebp
                    originalImg
                    originalName
                  }
                }
              }
              mediaItemUrl
            }
            title
          }
          aboutContent {
            content
            title
          }
          galleryContent {
            content
            title
            gallery {
              altText
              imageFile {
                childImageSharp {
                  fluid {
                    base64
                    tracedSVG
                    srcWebp
                    srcSetWebp
                    originalImg
                    originalName
                  }
                }
              }
              mediaItemUrl
            }
          }
          missionBanner {
            subTitle
            title
            image {
              altText
              imageFile {
                childImageSharp {
                  fluid {
                    base64
                    tracedSVG
                    srcWebp
                    srcSetWebp
                    originalImg
                    originalName
                  }
                }
              }
              mediaItemUrl
            }
          }
          teamContent {
            content
            title
            team {
              content
              position
              title
              image {
                altText
                imageFile {
                  childImageSharp {
                    fluid {
                      base64
                      tracedSVG
                      srcWebp
                      srcSetWebp
                      originalImg
                      originalName
                    }
                  }
                }
                mediaItemUrl
              }
            }
          }
        }
      }
      works {
        nodes {
          id
          featuredImage {
            node {
              imageFile {
                childImageSharp {
                  fluid {
                    base64
                    tracedSVG
                    srcWebp
                    srcSetWebp
                    originalImg
                    originalName
                  }
                }
              }
              sizes
              sourceUrl
              srcSet
            }
          }
          title
          slug
          content
        }
      }
    }
  }
`;
