import React from "react";
import { Link } from "gatsby";
import Img from "gatsby-image";
import styled from "styled-components";
import { Swiper, SwiperSlide } from "swiper/react";
import { SRLWrapper } from "simple-react-lightbox";
import NewsletterBanner from "../components/newsletter-banner";

const AboutStyles = styled.div`
  .page-title {
    padding: 4rem 3rem 5rem;

    h1 {
      font-size: 2.4rem;
      line-height: 3rem;
      border-bottom: 3px solid var(--text);
      padding-bottom: 1.5rem;
      margin-bottom: 3rem;
      text-transform: lowercase;
    }

    p {
      font-size: 1.4rem;
      line-height: 1.6rem;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .banner {
    background-size: cover;
    padding: 6rem 3rem;
    color: var(--white);
    position: relative;

    .img-wrap {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: -1;

      .gatsby-image-wrapper {
        width: 100%;
        height: 100%;
      }
    }

    .sub-title {
      margin-bottom: 2rem;
    }

    h2 {
      font-size: 2.4rem;
      line-height: 3.2rem;
      margin-bottom: 0;
    }
  }

  .page-content {
    padding: 5rem 3rem;
  }

  .creators-content {
    padding: 0 3rem 5rem;

    h2 {
      margin: 2rem 0 0;
    }

    .gatsby-image-wrapper {
      padding-bottom: 100%;
    }

    .position {
      font-style: italic;
      margin: 1rem 0 2rem;
      font-size: 1.4rem;
      line-height: 1.6rem;
    }

    p {
      line-height: 2.1rem;
    }

    .col:last-child {
      margin-top: 5rem;
    }
  }

  @media screen and (min-width: 981px) {
    .container {
      max-width: 1220px;
    }

    .page-title {
      padding: 8rem 0 9rem;

      .row {
        .col {
          flex-basis: 20%;

          &:last-child {
            flex-basis: 70%;
            margin-left: 10%;
          }
        }
      }

      h1 {
        font-size: 6rem;
        line-height: 6rem;
        margin-bottom: 8.5rem;
        padding-bottom: 2.5rem;
      }

      h2 {
        font-size: 3rem;
        line-height: 3.8rem;
      }

      p {
        font-size: 1.6rem;
        line-height: 2.1rem;
        margin-top: 0;
      }
    }

    .banner {
      padding: 25rem 3rem;

      .sub-title {
        margin-bottom: 5rem;
      }

      h2 {
        font-size: 3rem;
        line-height: 3.6rem;
      }
    }

    .page-content {
      padding: 12rem 3rem;

      .row {
        .col {
          flex-basis: 20%;

          &:last-child {
            flex-basis: 70%;
            margin-left: 10%;
          }
        }
      }

      h2 {
        font-size: 3rem;
        line-height: 3.8rem;
      }

      p {
        font-size: 1.6rem;
        line-height: 2.1rem;
        margin-top: 0;
      }
    }

    .gallery {
      .swiper-slide {
        width: 25%;

        a {
          display: block;
          position: relative;

          .gatsby-image-wrapper {
            padding-bottom: 100%;
          }
        }
      }
    }

    .creators-content {
      .container {
        overflow: visible;
      }

      .row {
        margin: 0 -8rem;
        width: calc(100% + 16rem);

        .col {
          position: relative;
          margin: 0 8rem;
          flex-basis: calc(50% - 16rem);
        }
      }

      h2 {
        font-size: 3rem;
        line-height: 3.6rem;
        position: absolute;
        top: 6rem;
        left: -3rem;
      }

      .position {
        margin-top: 5rem;
      }

      p {
        margin-bottom: 3rem;
      }
    }
  }
`;

const About = ({ data }) => (
  <AboutStyles>
    <div className="page-title">
      <div className="container">
        <h1>{data.title}</h1>
        <div className="row">
          <div className="col">
            <h2>{data.acfAbout.aboutContent.title}</h2>
          </div>
          <div className="col">
            <div
              dangerouslySetInnerHTML={{
                __html: data.acfAbout.aboutContent.content,
              }}
            />
          </div>
        </div>
      </div>
    </div>
    <div className="banner">
      <div className="img-wrap">
        <Img
          fluid={data.acfAbout.missionBanner.image.imageFile.childImageSharp.fluid}
          alt={data.acfAbout.missionBanner.title}
        />
      </div>
      <div className="container">
        <div className="sub-title">{data.acfAbout.missionBanner.subTitle}</div>
        <h2>{data.acfAbout.missionBanner.title}</h2>
      </div>
    </div>
    <div className="page-content content">
      <div className="container">
        <div className="row">
          <div className="col">
            <h2>{data.acfAbout.galleryContent.title}</h2>
          </div>
          <div className="col">
            <div
              dangerouslySetInnerHTML={{
                __html: data.acfAbout.galleryContent.content,
              }}
            />
          </div>
        </div>
      </div>
    </div>
    <div className="gallery">
      <SRLWrapper>
        <Swiper
          slidesPerView={"auto"}
          spaceBetween={10}
          breakpoints={{
            981: {
              slidesPerView: 4,
              spaceBetween: 15,
            },
          }}
        >
          {data.acfAbout.galleryContent.gallery.map((item, index) => (
            <SwiperSlide key={index}>
              <a href={item.mediaItemUrl}>
                <Img
                  loading="eager"
                  fluid={item.imageFile.childImageSharp.fluid}
                  alt={item.altText}
                />
              </a>
            </SwiperSlide>
          ))}
        </Swiper>
      </SRLWrapper>
    </div>
    <div className="page-content content">
      <div className="container">
        <div className="row">
          <div className="col">
            <h2>{data.acfAbout.teamContent.title}</h2>
          </div>
          <div className="col">
            <div
              dangerouslySetInnerHTML={{
                __html: data.acfAbout.teamContent.content,
              }}
            />
          </div>
        </div>
      </div>
    </div>
    <div className="creators-content content">
      <div className="container">
        <div className="row">
          {data.acfAbout.teamContent.team.map((item, index) => (
            <div className="col" key={index}>
              <Img fluid={item.image.imageFile.childImageSharp.fluid} alt={item.title} />
              <h2>{item.title}</h2>
              <div className="position">{item.position}</div>
              <div
                dangerouslySetInnerHTML={{
                  __html: item.content,
                }}
              />
            </div>
          ))}
        </div>
      </div>
    </div>

    <NewsletterBanner data={data.acfAbout.aboutBottomBanner} />
  </AboutStyles>
);

export default About;
