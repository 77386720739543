import React from "react";
import { Link, StaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import styled from "styled-components";
import SlideBg1 from "../images/hp-slide-1@2x.jpg";
import SlideBg2 from "../images/hp-slide-2@2x.jpg";

const WorkStyles = styled.div`
  .work {
    position: relative;

    .gatsby-image-wrapper {
      padding-bottom: 75%;
    }

    a {
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: 100%;
      height: 100%;
      text-decoration: none;
      color: var(--white);
      padding: 2.5rem;
      box-sizing: border-box;
      z-index: 2;

      h2 {
        display: inline-block;
        font-family: "Roboto Condensed", sans-serif;
        font-weight: 700;
        text-transform: uppercase;
        margin: 0;
        font-size: 1.6rem;
        line-height: 1.9rem;
        text-shadow: 2px 2px 19px rgba(0, 0, 0, 0.25);
        padding-bottom: 5px;
        position: relative;

        &:after {
          position: absolute;
          left: 0;
          bottom: -5px;
          content: "";
          width: 0;
          height: 3px;
          display: block;
          background: var(--white);
          transition: width 0.3s ease;
        }
      }

      &:after {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.67);
        z-index: -1;
        opacity: 0;
        transition: opacity 0.3s ease;
      }

      &:hover {
        h2 {
          &:after {
            width: 100%;
          }
        }

        &:after {
          opacity: 1;
        }
      }
    }
  }

  @media screen and (min-width: 981px) {
    display: flex;
    flex-wrap: wrap;

    .work {
      flex: 0 0 33.3333%;

      a {
        padding: 5rem;
      }
    }
  }
`;

const Work = ({ data }) => (
  <WorkStyles>
    <StaticQuery
      query={graphql`
        query WorksQuery {
          wpgraphql {
            works {
              nodes {
                id
                featuredImage {
                  node {
                    imageFile {
                      childImageSharp {
                        fluid {
                          base64
                          tracedSVG
                          srcWebp
                          srcSetWebp
                          originalImg
                          originalName
                        }
                      }
                    }
                    sizes
                    sourceUrl
                    srcSet
                  }
                }
                title
                slug
                content
              }
            }
          }
        }
      `}
      render={data => {
        const works = data.wpgraphql.works.nodes;
        let content;

        if (works.length) {
          content = works.map(work => {
            return (
              <div className="work" key={work.id}>
                <Img
                  fluid={work.featuredImage.node.imageFile.childImageSharp.fluid}
                  alt={work.title}
                />
                <Link to={`/work/${work.slug}`}>
                  <h2>{work.title}</h2>
                </Link>
              </div>
            );
          });
        }

        return content;
      }}
    />
  </WorkStyles>
);

export default Work;
