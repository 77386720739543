import React from "react";
import { Link } from "gatsby";
import Img from "gatsby-image";
import ProcessStyles from "../assets/styles/ProcessStyles";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation } from "swiper";
SwiperCore.use([Navigation]);

const Production = ({ data, works }) => {
  return (
    <ProcessStyles>
      <div className="page-title">
        <div className="container">
          <h1>{data.title}</h1>
          <div
            dangerouslySetInnerHTML={{
              __html: data.content,
            }}
          />
        </div>
      </div>
      <div className="page-content">
        <div className="container">
          <div className="row">
            <div className="col">
              <Img
                fluid={data.acfProduction.productionContent.image.imageFile.childImageSharp.fluid}
                alt={data.acfProduction.productionContent.image.altText}
              />
            </div>
            <div className="col">
              <div className="content">
                <div
                  dangerouslySetInnerHTML={{
                    __html: data.acfProduction.productionContent.contentRight,
                  }}
                />
              </div>
            </div>
          </div>
          <div className="row full-width">
            <div className="col">
              <div className="content">
                <div
                  dangerouslySetInnerHTML={{
                    __html: data.acfProduction.productionContent.contentBottom,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="process-list">
        <div className="img-wrap">
          <Img
            fluid={data.acfProduction.types.image.imageFile.childImageSharp.fluid}
            alt={data.acfProduction.types.title}
          />
        </div>
        <div className="container">
          <h2>{data.acfProduction.types.title}</h2>
          <ul>
            {data.acfProduction.types.content.map((item, index) => (
              <li key={index}>
                <i className="gg-arrow-long-right"></i>
                <h3>{item.title} / </h3>
                <div
                  dangerouslySetInnerHTML={{
                    __html: item.content,
                  }}
                />
              </li>
            ))}
          </ul>
        </div>
      </div>

      <section className="process-slider">
        <Swiper slidesPerView={"auto"} spaceBetween={10} navigation>
          {works.nodes.map(work => (
            <SwiperSlide>
              <Img
                fluid={work.featuredImage.node.imageFile.childImageSharp.fluid}
                alt={work.title}
              />
              <Link to={`/work/${work.slug}`}>
                <h3>{work.title}</h3>
                <div
                  dangerouslySetInnerHTML={{
                    __html: work.content,
                  }}
                />
              </Link>
            </SwiperSlide>
          ))}
        </Swiper>
      </section>

      <section className="book">
        <div className="img-wrap">
          <Img
            fluid={data.acfProduction.productionBanner.image.imageFile.childImageSharp.fluid}
            alt={data.acfProduction.productionBanner.title}
          />
        </div>
        <div className="container">
          <div className="row">
            <div className="col">
              <div className="book-inner">
                <h2>{data.acfProduction.productionBanner.title}</h2>
                <Link
                  to={data.acfProduction.productionBanner.button.url}
                  className="button button-light"
                >
                  {data.acfProduction.productionBanner.button.title}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </ProcessStyles>
  );
};

export default Production;
