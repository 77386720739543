import React from "react";
import { Link } from "gatsby";
import Img from "gatsby-image";
import styled from "styled-components";

const NewsLetterBannerStyles = styled.div`
  background-size: cover;
  padding: 8rem 3rem;
  color: var(--white);
  position: relative;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    background: var(--text);
    background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(41, 41, 41, 1) 100%);
  }

  .img-wrap {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;

    .gatsby-image-wrapper {
      width: 100%;
      height: 100%;
    }
  }

  h2 {
    font-size: 3rem;
    line-height: 4.5rem;
  }

  p {
    line-height: 2rem;
    margin-bottom: 2rem;
  }

  .button {
    margin-bottom: 0;
  }

  .newsletter-inner {
    position: relative;
    z-index: 2;
  }

  @media screen and (min-width: 981px) {
    padding: 12rem 3rem;

    h2 {
      font-size: 6rem;
      line-height: 6rem;
    }

    .row {
      .col {
        width: 50%;
        margin-left: 50%;
      }
    }
  }
`;

const NewsLetterBanner = ({ data }) => (
  <NewsLetterBannerStyles>
    <div className="img-wrap">
      <Img fluid={data.image.imageFile.childImageSharp.fluid} alt={data.image.title} />
    </div>
    <div className="container">
      <div className="row">
        <div className="col">
          <div className="newsletter-inner">
            <h2>{data.title}</h2>
            <div
              dangerouslySetInnerHTML={{
                __html: data.content,
              }}
            />
            <Link to={data.button.url} className="button button-light">
              {data.button.title}
            </Link>
          </div>
        </div>
      </div>
    </div>
  </NewsLetterBannerStyles>
);

export default NewsLetterBanner;
