import React from "react";
import { Link } from "gatsby";
import SEO from "../components/seo";
import styled from "styled-components";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination, Navigation } from "swiper";
SwiperCore.use([Pagination, Navigation]);
import HeaderBg from "../images/hp-header@2x.jpg";

const HomepageStyles = styled.div`
  .top-banner {
    position: relative;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    color: var(--white);
    padding: 3rem;
    min-height: 480px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-top: -90px;
    box-sizing: border-box;
    overflow: hidden;

    .img-wrap {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: -1;

      .gatsby-image-wrapper {
        width: 100%;
        height: 100%;
      }
    }

    .container {
      width: 100%;
    }

    .sub-title {
      margin-bottom: 1.5rem;
    }

    h1 {
      max-width: 50%;
      line-height: 4rem;
    }

    .button {
      margin-bottom: 0;
      flex: 0 0 auto;
    }
  }

  .service {
    margin-bottom: 0.8rem;
  }

  .slider {
    .swiper-slide {
      position: relative;
      background-size: cover;
      width: 75% !important;
      overflow: hidden;

      &:after {
        content: "";
        display: block;
      }

      .gatsby-image-wrapper {
        padding-bottom: 100%;
      }

      a {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: calc(100% - 3rem);
        height: calc(100% - 3rem);
        padding: 1.5rem;
        color: var(--white);
        font-family: "Roboto Condensed", sans-serif;
        font-size: 1.4rem;
        line-height: 1.7rem;
        font-weight: 700;
        text-decoration: none;
        z-index: 2;

        &:after {
          content: "";
          position: absolute;
          opacity: 0;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, 0.8);
          transition: opacity 0.3s ease;
          z-index: -1;
        }

        &:hover {
          &:after {
            opacity: 1;
          }
        }
      }
    }
  }

  .testimonials {
    position: relative;
    background: var(--text);
    padding: 6rem 0;

    blockquote {
      z-index: 3;
      position: absolute;
      top: 3px;
      left: 18px;
      font-size: 180px;
      font-weight: 700;
      color: var(--grey);
      margin: 0;
      padding: 0;
    }

    .swiper-pagination {
      position: absolute;
      top: 125px;
      left: 28px;
      z-index: 3;
      display: flex;

      .swiper-pagination-bullet {
        width: 15px;
        height: 2px;
        background: var(--grey);
        margin: 0 2px;
        cursor: pointer;

        &.swiper-pagination-bullet-active {
          background: var(--blue);
        }
      }
    }

    .swiper-slide {
      padding: 9rem 3rem;
      box-sizing: border-box;
      background: var(--white);
      width: 85% !important;

      &:last-child {
        margin-right: 15%;
      }

      p {
        font-weight: 700;
      }

      .author {
        margin-top: 3rem;
      }

      &:after {
        display: none;
      }
    }
  }

  .newsletter {
    background-size: cover;
    padding: 8rem 3rem;
    color: var(--white);
    position: relative;

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      width: 100%;
      height: 100%;
      background: var(--text);
      background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(41, 41, 41, 1) 100%);
    }

    .img-wrap {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: -1;

      .gatsby-image-wrapper {
        width: 100%;
        height: 100%;
      }
    }

    h2 {
      font-size: 3rem;
      line-height: 4.5rem;
    }

    p {
      line-height: 2rem;
      margin-bottom: 2rem;
    }

    .button {
      margin-bottom: 0;
    }

    .newsletter-inner {
      position: relative;
      z-index: 2;
    }
  }

  @media screen and (min-width: 981px) {
    .top-banner {
      margin-top: -132px;
      min-height: 100vh;

      h1 {
        line-height: 6rem;
        max-width: 100%;
      }

      .col {
        max-width: 50%;
      }
    }

    .content-top {
      .row {
        align-items: center;
      }

      .col {
        &:first-child {
          margin-right: 18rem;
        }

        h2 {
          &:last-child {
            margin-bottom: 0;
          }
        }

        p {
          font-size: 3rem;
          color: var(--dark);
          line-height: 3.6rem;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }

    .slider {
      .swiper-container {
        position: relative;
      }

      .swiper-slide {
        width: 30% !important;

        a {
          font-size: 1.6rem;
          padding: 5rem;
        }
      }

      .swiper-button-disabled {
        opacity: 0.5 !important;
      }

      .swiper-button-prev {
        box-sizing: border-box;
        position: absolute;
        display: block;
        width: 32px;
        height: 32px;
        border: 2px solid;
        border-radius: 100px;
        color: var(--white);
        z-index: 3;
        top: calc(50% - 16px);
        left: 5rem;
        cursor: pointer;
        opacity: 0.8;
      }

      .swiper-button-prev::after {
        content: "";
        display: block;
        box-sizing: border-box;
        position: absolute;
        width: 10px;
        height: 10px;
        border-bottom: 2px solid;
        border-left: 2px solid;
        transform: rotate(45deg);
        left: 11px;
        top: 9px;
      }

      .swiper-button-next {
        box-sizing: border-box;
        position: absolute;
        display: block;
        width: 32px;
        height: 32px;
        border: 2px solid;
        border-radius: 100px;
        color: var(--white);
        z-index: 3;
        top: calc(50% - 16px);
        right: 5rem;
        cursor: pointer;
        opacity: 0.8;
      }

      .swiper-button-next::after {
        content: "";
        display: block;
        box-sizing: border-box;
        position: absolute;
        width: 10px;
        height: 10px;
        border-bottom: 2px solid;
        border-right: 2px solid;
        transform: rotate(-45deg);
        right: 11px;
        top: 9px;
      }

      .swiper-button-prev,
      .swiper-button-next {
        transition: opacity 0.3s ease;

        &:hover {
          opacity: 1;
        }
      }
    }

    .services {
      .row {
        margin: 0 -6rem;

        .col {
          flex-basis: 33.3333%;

          .service {
            padding: 0 6rem;
          }
        }
      }
    }

    .testimonials {
      padding: 12rem 0;

      .swiper-slide {
        width: 60% !important;
        padding: 12rem;

        &:last-child {
          margin-right: 0;
        }
      }

      blockquote {
        left: 21%;
        top: 63px;
      }

      .swiper-pagination {
        width: 60%;
        top: 71px;
        left: calc(50% + 12rem);
        transform: translateX(-50%);

        .swiper-pagination-bullet {
          width: 30px;
          margin: 0 4px;
        }
      }
    }

    .newsletter {
      padding: 12rem 3rem;

      h2 {
        font-size: 6rem;
        line-height: 6rem;
      }

      .row {
        .col {
          width: 50%;
          margin-left: 50%;
        }
      }
    }
  }
`;

const Home = ({ data }) => (
  <>
    <SEO title="Home" description="" image="" />

    <HomepageStyles>
      <section className="top-banner">
        {data.acfHome.topBanner.image && (
          <div className="img-wrap">
            <Img
              fluid={data.acfHome.topBanner.image.imageFile.childImageSharp.fluid}
              alt={data.acfHome.topBanner.title}
            />
          </div>
        )}
        <div className="container">
          <div className="row">
            <div className="col">
              <div className="sub-title">{data.acfHome.topBanner.subTitle}</div>
              <h1>{data.acfHome.topBanner.title}</h1>
              <div className="desktop">
                <div
                  dangerouslySetInnerHTML={{
                    __html: data.acfHome.topBanner.content,
                  }}
                />
              </div>
              <Link to={data.acfHome.topBanner.button.url} className="button button-light">
                {data.acfHome.topBanner.button.title}
              </Link>
            </div>
          </div>
        </div>
      </section>

      <section className="content content-top">
        <div className="container">
          <div className="row">
            <div className="col">
              <div className="sub-title">{data.acfHome.mainContent.subTitle}</div>
              <h2>{data.acfHome.mainContent.title}</h2>
            </div>
            <div className="col">
              <div
                dangerouslySetInnerHTML={{
                  __html: data.acfHome.mainContent.content,
                }}
              />
            </div>
          </div>
        </div>
      </section>

      <section className="slider">
        <Swiper slidesPerView={"auto"} spaceBetween={4} navigation>
          {data.works.nodes.map(work => (
            <SwiperSlide key={work.id}>
              <Img
                fluid={work.featuredImage.node.imageFile.childImageSharp.fluid}
                alt={work.title}
              />
              <Link to={`/work/${work.slug}`}>ELECTRONICS INTERNATIONAL INC.</Link>
            </SwiperSlide>
          ))}
        </Swiper>
      </section>

      <section className="content services">
        <div className="container">
          <div className="row">
            {data.acfHome.process.map(item => (
              <div className="col">
                <div className="service">
                  <h2>{item.title}</h2>
                  <div
                    className="content-wrap"
                    dangerouslySetInnerHTML={{
                      __html: item.content,
                    }}
                  />
                  <Link to={item.button.url} className="button">
                    {item.button.title}
                  </Link>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      <section className="slider testimonials">
        <blockquote>“</blockquote>
        <Swiper
          slidesPerView={"auto"}
          spaceBetween={20}
          pagination={{ clickable: true }}
          breakpoints={{
            981: {
              centeredSlides: true,
              initialSlide: 2,
              spaceBetween: 120,
            },
          }}
        >
          {data.acfHome.testimonials.map(testimonial => (
            <SwiperSlide>
              <div
                dangerouslySetInnerHTML={{
                  __html: testimonial.content,
                }}
              />
              <div className="author">
                <strong>— {testimonial.author}</strong>, {testimonial.title}
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </section>

      <section className="newsletter">
        {data.acfHome.bottomBanner.image && (
          <div className="img-wrap">
            <Img
              fluid={data.acfHome.bottomBanner.image.imageFile.childImageSharp.fluid}
              alt={data.acfHome.bottomBanner.title}
            />
          </div>
        )}
        <div className="container">
          <div className="row">
            <div className="col">
              <div className="newsletter-inner">
                <h2>{data.acfHome.bottomBanner.title}</h2>
                <div
                  dangerouslySetInnerHTML={{
                    __html: data.acfHome.bottomBanner.content,
                  }}
                />
                <Link to={data.acfHome.bottomBanner.button.url} className="button button-light">
                  {data.acfHome.bottomBanner.button.title}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </HomepageStyles>
  </>
);

export default Home;
