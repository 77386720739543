import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import ReactPlayer from "react-player";
import { Swiper, SwiperSlide } from "swiper/react";
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox";
import SlideBg1 from "../images/hp-slide-1@2x.jpg";
import SlideBg2 from "../images/hp-slide-2@2x.jpg";
import Play from "../assets/svg/play.svg";

const VideoStyles = styled.div`
  .player-wrapper {
    position: relative;
    padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
  }

  .react-player {
    position: absolute;
    top: 0;
    left: 0;

    svg {
      filter: drop-shadow(2px 2px 18px var(--black));
    }
  }

  .page-content {
    padding: 5rem 3rem;

    .sub-title {
      font-family: Helvetica, Arial, sans-serif;
      margin-bottom: 2.5rem;
    }

    h1 {
      margin-bottom: 5rem;
    }

    .site-link {
      color: var(--blue);
      text-decoration: none;
      margin-bottom: 3rem;
      display: block;
    }

    p {
      line-height: 2.1rem;
      color: var(--p-grey);
    }

    .staff {
      margin: 5rem 0;
      font-weight: 700;

      &:last-child {
        margin-bottom: 0;
      }

      .position {
        font-family: "Roboto Condensed", sans-serif;
        font-weight: 700;
        text-transform: uppercase;
        font-size: 1.4rem;
        color: var(--blue);
      }
    }
  }

  .gallery {
    padding: 3rem 0;

    .swiper-slide {
      width: 75% !important;

      a {
        display: block;
        position: relative;
        padding-bottom: 100%;

        img {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }

  .testimonial {
    position: relative;
    padding: 12rem 3rem 7rem;
    color: var(--p-grey);

    blockquote {
      z-index: 3;
      position: absolute;
      top: 3px;
      left: 18px;
      font-size: 180px;
      font-weight: 700;
      color: var(--text);
      margin: 0;
      padding: 0;
    }

    .author {
      margin-top: 4rem;
    }
  }

  @media screen and (min-width: 981px) {
    .player-wrapper {
      .react-player {
        svg {
          width: 85px;
          height: 104px;
        }
      }
    }

    .page-content {
      padding: 15rem 3rem;

      .row {
        .col {
          flex: 0 0 calc(20% - 2rem);

          .site-link {
            margin-bottom: 0;
          }

          &:nth-child(2) {
            flex: 0 0 calc(45% - 2rem);
            margin: 0 10%;

            p {
              margin-bottom: 4rem;

              &:last-child {
                margin-bottom: 0;
              }
            }
          }

          &:last-child {
            flex: 0 0 calc(15% - 2rem);
            display: flex;
            flex-direction: column;
            align-items: flex-end;

            .staff {
              font-size: 1.6rem;
              margin: 0 0 5rem;

              &:last-child {
                margin-bottom: 0;
              }
            }
          }
        }
      }
    }

    .gallery {
      padding: 0;

      .swiper-container {
        overflow: visible;
      }

      .swiper-wrapper {
        width: 100% !important;
        flex-wrap: wrap;
        transform: none !important;
      }

      .swiper-slide {
        width: 20% !important;
        flex: 0 0 20%;
        padding: 1px;
        box-sizing: border-box;

        a {
          transition: opacity 0.3s ease;

          &:hover {
            opacity: 0.7;
          }
        }
      }
    }

    .testimonial {
      position: relative;
      padding: 15rem 3rem 15rem 18rem;
      font-size: 2rem;
      line-height: 2.4rem;

      blockquote {
        top: 230px;
        left: 30px;
      }

      p {
        font-size: 2rem;
        line-height: 2.4rem;
      }

      .author {
        margin-top: 4rem;
      }
    }
  }
`;

const VideoPage = ({ data }) => (
  <VideoStyles>
    <div className="player-wrapper">
      <ReactPlayer
        url="https://vimeo.com/video/486619724"
        className="react-player"
        light
        playing
        playIcon={<Play />}
        controls
        width="100%"
        height="100%"
      />
    </div>
    <div className="container">
      <div className="page-content content">
        <div className="row">
          <div className="col">
            <div className="sub-title">PROJECT TYPE : COMMERCIAL</div>
            <h1>deschutes brewery</h1>
            <a href="https://deschutesbrewery.com" className="site-link">
              deschutesbrewery.com
            </a>
          </div>
          <div className="col">
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
              incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
              exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure
              dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
              Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt
              mollit anim id est laborum.
            </p>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
              incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
              exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure
              dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
              Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt
              mollit anim id est laborum.
            </p>
          </div>
          <div className="col">
            <div className="staff">
              <div className="position">DIRECTOR</div>
              First Name, Last Name
            </div>
            <div className="staff">
              <div className="position">DIRECTOR</div>
              First Name, Last Name
            </div>
            <div className="staff">
              <div className="position">DIRECTOR</div>
              First Name, Last Name
            </div>
          </div>
        </div>
      </div>
      <div className="gallery">
        <SimpleReactLightbox>
          <SRLWrapper>
            <Swiper
              slidesPerView={"auto"}
              spaceBetween={2}
              breakpoints={{
                981: {
                  spaceBetween: 0,
                },
              }}
            >
              <SwiperSlide>
                <a href={SlideBg1}>
                  <img src={SlideBg1} alt="Gallery Item" />
                </a>
              </SwiperSlide>
              <SwiperSlide>
                <a href={SlideBg2}>
                  <img src={SlideBg2} alt="Gallery Item" />
                </a>
              </SwiperSlide>
              <SwiperSlide>
                <a href={SlideBg1}>
                  <img src={SlideBg1} alt="Gallery Item" />
                </a>
              </SwiperSlide>
              <SwiperSlide>
                <a href={SlideBg2}>
                  <img src={SlideBg2} alt="Gallery Item" />
                </a>
              </SwiperSlide>
              <SwiperSlide>
                <a href={SlideBg1}>
                  <img src={SlideBg1} alt="Gallery Item" />
                </a>
              </SwiperSlide>
              <SwiperSlide>
                <a href={SlideBg2}>
                  <img src={SlideBg2} alt="Gallery Item" />
                </a>
              </SwiperSlide>
              <SwiperSlide>
                <a href={SlideBg1}>
                  <img src={SlideBg1} alt="Gallery Item" />
                </a>
              </SwiperSlide>
              <SwiperSlide>
                <a href={SlideBg2}>
                  <img src={SlideBg2} alt="Gallery Item" />
                </a>
              </SwiperSlide>
              <SwiperSlide>
                <a href={SlideBg1}>
                  <img src={SlideBg1} alt="Gallery Item" />
                </a>
              </SwiperSlide>
              <SwiperSlide>
                <a href={SlideBg2}>
                  <img src={SlideBg2} alt="Gallery Item" />
                </a>
              </SwiperSlide>
            </Swiper>
          </SRLWrapper>
        </SimpleReactLightbox>
      </div>
      <div className="testimonial">
        <blockquote>“</blockquote>
        <p>
          The good people at WK Studios are truly talented at what they do. I've been quite
          impressed by the scope and quality of what they are able to produce and conceptualize. I
          would feel comfortable referring them to anyone in my network knowing that Paul will give
          honest and accurate feedback and seems to genuinely care about the client's goals.
        </p>
        <div className="author">
          <strong>— Rodney Smith</strong>, Title Here, Company Here
        </div>
      </div>
    </div>
  </VideoStyles>
);

export default VideoPage;
